'use client'

import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'
import { ErrorCdsNotice } from './components/ErrorCdsNotice/errorCdsNotice'

export default function ErrorPage({
  error,
}: {
  error: Error & { digest?: string }
}) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])
  return (
    <html>
      <body>
        <html>
          <body>
            <ErrorCdsNotice httpError={error}></ErrorCdsNotice>
          </body>
        </html>
      </body>
    </html>
  )
}
